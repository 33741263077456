<template>
  <div class="home qjc-theme-bg qjc-c-thin">
    <div class="home-logo">
      <img src="@/assets/images/logo2.png" alt="">
    </div>
    <div class="home-content">
      <div class="question-btn" @click="toQuestion('/jkzc/question')">
        無創AI自測
      </div>

      <div class="question-btn" @click="toQuestion('/bj/question')">
        AI體檢(需要血液檢查指標)
      </div>

      <div class="question-btn" @click="toQuestion('/four/question')">
        AI急重症預警
      </div>
    </div>

    <!-- 底部導航 -->
    <van-tabbar v-model="navIcon.active" active-color="#6681FA" inactive-color="#9DA6B9" class="history-tabbar">
      <van-tabbar-item name="home" to="/">
        <span>首頁</span>
        <img slot="icon" slot-scope="props" :src="props.active ? navIcon.home.active : navIcon.home.normal">
      </van-tabbar-item>

      <van-tabbar-item name="user" to="/user/history">
        <span>我的</span>
        <img slot="icon" slot-scope="props" :src="props.active ? navIcon.user.active : navIcon.user.normal">
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import Vue from 'vue'
import { NavBar, Image, Grid, GridItem, Tabbar, TabbarItem, Button, ImagePreview, Popup, Toast } from 'vant'
Vue.use(NavBar)
  .use(Image)
  .use(Grid)
  .use(GridItem)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Button)
  .use(Toast)
  .use(ImagePreview)
  .use(Popup);

import { isPay, hasToken, packagePrice } from "@/js/axios"

export default {
  name: "home",
  data () {
    return {
      navIcon: {
        active: 'home',
        home: {
          normal: require('@/assets/images/shouye1.png'),
          active: require('@/assets/images/shouye.png')
        },
        study: {
          normal: require('@/assets/images/study.png'),
          active: require('@/assets/images/study_active.png')
        },
        learn: {
          normal: require('@/assets/images/learn.png'),
          active: require('@/assets/images/learn-active.png')
        },
        user: {
          normal: require('@/assets/images/wode.png'),
          active: require('@/assets/images/wode2.png')
        }
      },// 底部導航欄icon
      isPerfectInfo: true, //是否完善信息
      goodsList: [],
    }
  },
  created () {
  },
  mounted () {
    sessionStorage.removeItem('package');
    // 已登錄存在未完成問卷提示
    if (this.$store.state.isLogin) {
      let userInfo = JSON.parse(localStorage.getItem('user'))
      if (userInfo && userInfo.birth != '' && userInfo.sex != 0) {
        this.isPerfectInfo = true
      } else {
        this.isPerfectInfo = false
      }

    }
  },
  methods: {
    toQuestion (url) {
      this.$router.push(url);
    },
    buyCombo (p, path) {
      sessionStorage.setItem('package', p);
      this.$router.push(path ? path : '/home/buyGoods');
    },
    handleToQuestion (id) {
      sessionStorage.setItem('goods_id', id);
      this.$router.push('/aizh/question');
    },
    getPackageInfo () {//獲取套餐信息
      packagePrice().then(res => {
        if (res.status == 1) {
          var result = res.data;
          this.goodsList = result
        } else {
          Toast.fail(res.msg)
        }
      });
    },
  }
}
</script>

<style scoped></style>
<style lang="scss" scoped>
.home {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-image: url(../../assets/images/home_bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  .history-tabbar {
    flex: 0 0 auto;
    position: relative !important;
  }

  .home-logo {
    position: relative;
    width: 100%;
    padding: 20px 10%;
    height: fit-content;

    img {
      width: 100%;
    }
  }

  .home-content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: .24rem .72rem;
    overflow: auto;

    .question-btn {
      flex: 0 0 auto;
      height: 52px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
      background-image: url(../../assets/images/home_btn_bg.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    }


    .van-button {
      width: 100%;
      margin-bottom: .24rem;
    }
  }
}
</style>
